
.report {
  display: flex;
  margin: 0;
  align-items:center;
  .overview {
    flex-grow: 1;
    .week {
      font-size: 11px;
      line-height: 14px;
      font-weight: bold;
      color: var(--primary);
      text-transform: uppercase;
    }
  }
  .button {
    flex-grow: 0;
  }
}
