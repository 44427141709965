@import "~@o4c/css/src/mixins/typography";

.o4c.timeline {
  padding: 0;
  margin: 0;
}
.o4c.timeline .primary-report {
  padding-top: 40px;
}
.o4c.timeline .primary-report::before {
  top: 40px;
}
.o4c.timeline .primary-report::after {
  top: 45px;
}

.title-header {
  @include titleHeader();
  color: var(--primary);
}

.comment {
  display: flex;
  padding: 24px 0 40px 0;
  span {
    display: block;
    @include captionText();
  }
  .icon {
    color: var(--primary);
  }
}

.report-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100vh;
  width: 100vw;
}
